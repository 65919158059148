import React, { Component } from 'react';
import { DropdownItem, DropdownMenu, DropdownToggle, Nav } from 'reactstrap';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom'

import { AppAsideToggler, AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import logo from '../../assets/img/brand/logo.png'
import logout from '../../services/logout'

import { generateLoginUrl } from "../../services/authenticate";
const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};


class DefaultHeader extends Component {
  cerrarSesion(){
    logout()
  }
  changePassword(){
    this.props.showPasswordDialog()
  }

  render() {
    // eslint-disable-next-line
    const { children, ...attributes } = this.props;
    const alertas = this.props.alerts
    const vistas = this.props.vistas
    const importantes = this.props.importantes
    const cantidad = this.props.cantidad
    // var badge;
    // if (alertas !== undefined && vistas !== undefined)    
    var  badge =  cantidad !== 0 ? <span className="badge badge-danger badge-pill">{cantidad}</span>
      : null
    // if (cantidadDeAlarmas === 0 && vistas === 0)
    //   badge = null
    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand
          full={{ src: logo, width: "100%", alt: 'Logo' }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />

        <Nav className="ml-auto" navbar>
          <AppHeaderDropdown direction="down">
            <DropdownToggle nav>
              <img src={'assets/img/avatars/1.png'} className="img-avatar" alt="admin@bootstrapmaster.com" /> {/*TODO*/}
            </DropdownToggle>
            <DropdownMenu right style={{ right: 'auto' }}>
              <div className="usernameDiv" style={{backgroundColor: "lightgray", textAlign: "center"}}>{localStorage.getItem("user") ? localStorage.getItem("user").split('@')[0] : ""}</div>
              <DropdownItem onClick={()=> this.changePassword()}><i className="fa fa-key"></i> Cambiar contraseña </DropdownItem>
              <DropdownItem onClick={this.props.addUser}><i className="fa fa-user-plus"></i> Agregar usuario </DropdownItem>
              <Link to={generateLoginUrl()} > <DropdownItem onClick={this.cerrarSesion}><i className="fa fa-lock"></i> Cerrar sesión </DropdownItem></Link>
            </DropdownMenu>
          </AppHeaderDropdown>
        </Nav>
        <AppAsideToggler mobile >
          <i className="icon-bell"></i>{badge}
        </AppAsideToggler>
      </React.Fragment>
    );
  }
}


DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
