import config from '../config'

function getServiceData(callback){
    const url = `${config.serverUrl}:${config.serverPort}/api/serviceData`
    fetch(url,{
        headers:{
          'Authorization': `Bearer ${localStorage.getItem('access-token')}`
        }
      })
    .then(response => response.json()).then(data =>{
        callback(data.service)
    })
    .catch(err => console.error("Error al contactar el servidor ", err))
}

export default getServiceData