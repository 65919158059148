import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import alert from '../../../assets/img/icons/alert.png'
import './styles.css'

class Page401 extends Component {
  render() {
    return (
      <div className="align-items-center pag401">
        <Container>
          <Row className="justify-content-center">
            <Col md="6">
              <img className="alert" src={alert} alt="alert"/>
              <span className="clearfix">
                <h4 className="pt-3">Lo sentimos, usted no tiene acceso a esta página.</h4>
                <p className="text-muted float-left">Si cree que esto es un error, por favor póngase en contacto con un administador del sistema.</p>
              </span>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Page401;
