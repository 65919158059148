import config from '../config'

const url = `${config.serverUrl}:${config.serverPort}/api/notifier/`

function getAllNotifier() {
    return fetch(`${url}rules/`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access-token')}`,
            'service': `${localStorage.getItem('service')}`,
            'user': `${localStorage.getItem('user')}`
        },
    })
        .then(response => {
            if (response.status === 200 || response.status === 304)
                return response.json()
            else
                return Promise.reject("Too many failed connections to server")
        })
}


function getNotifier(atributo, sensor) {
    return fetch(`${url}rules/device/${sensor}/attr/${atributo}`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('access-token')}`,
            'service': `${localStorage.getItem('service')}`,
            'user': `${localStorage.getItem('user')}`
        },
    })
        .then(response => {
            if (response.status === 200 || response.status === 304)
                return response.json()
            else
                return Promise.reject("Too many failed connections to server")
        })
}

function deleteNotifier(idRules){
    return fetch(`${url}rules/${idRules}`,{
      method:"DELETE",
      headers:{
        'Authorization': `Bearer ${localStorage.getItem('access-token')}`,
        'service': `${localStorage.getItem('service')}`,
        'Content-Type': 'application/json'
      },
    })
  
  }

  async function addNotifier(usuario, service, atributo, sensor, operacion, valor, destinatarios, tipo){
    const resp = await fetch(`${url}rules/`, {
          method: "POST",
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('access-token')}`,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ usuario, service, atributo, sensor, operacion, valor, destinatarios, tipo })
      })
      return console.log(resp)
  }
export default getNotifier
export {deleteNotifier, addNotifier, getAllNotifier}