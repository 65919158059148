import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import authenticate from '../../../services/authenticate'


class Login extends Component {
  constructor(props){
    super(props)

    this.handleMailChange = this.handleMailChange.bind(this)
    this.handlePassChange = this.handlePassChange.bind(this)
    this.handleLogin = this.handleLogin.bind(this)

    this.state = {errorMessage: ""}
  }
  handleLogin(e){
    authenticate(this.state.mail, this.state.pass, (err, data) =>{
      if(err){
        this.setState({errorMessage: "Usuario o contraseña incorrectos"})
        return
      }
      const token = data.token
      localStorage.setItem('access-token', token)
      localStorage.setItem('service', data.service)
      localStorage.setItem('user', this.state.mail)
      localStorage.setItem('rol', data.rol)
      this.props.history.push('/mapContainer')
    })
  }
  handleMailChange(event){
    this.setState({
      mail: event.target.value,
    })
  }
  handlePassChange(event){
    this.setState({
      pass: event.target.value,
    })
  }
  render() {
    return (
      <div className="app flex-row align-items-center loginPage">
        <Container>
          <Row className="justify-content-center">
            <Col md="6" lg="6">
              <CardGroup>
                <Card className="loginBox p-4">
                <img src="/assets/img/loginlogo.png" alt="IOTACCSA" className="loginlogo center img-fluid"></img>
                  <CardBody>
                    <Form onSubmit={this.handleLogin}>
                      <h1>Iniciar sesión</h1>
                      <p className="text-muted2">Inicie sesión en su cuenta</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-user"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="text" onChange={this.handleMailChange} placeholder="Correo electrónico" autoComplete="username" />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" onChange={this.handlePassChange} placeholder="Contraseña" autoComplete="current-password" />
                      </InputGroup>
                      <h4 style={{color: "red"}}> {this.state.errorMessage} </h4>
                      <Row>
                        <Col xs="6">
                          <Button type="submit" color="primary" className="px-4">Ingresar</Button>
                        </Col>
                        {/* <Col xs="6" className="text-right">
                          <Button color="link" className="px-0">¿Olvidó su contraseña?</Button>
                        </Col> */}
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
