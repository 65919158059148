import config from '../config'

const url = config.serverUrl + ':' +  config.serverPort + '/api/sucursales/'

var markers = []
var readyToAsk = true //Se usa para no saturar al servidor y que no haya más de una petición al mismo tiempo
/**
 * @author Juan Andrés Mezzera
 * @method initializeMarkers
 * @description Se encarga de mantener la variable markers actualizada
 */

var interval
function initializeMarkers(callback){
  getMarkers(values =>{
    markers = values
    callback(markers)
  })
  interval = setInterval(() => getMarkers(values =>markers = values), config.refreshValuesRate)
}
function clearMarkersInterval(){
  clearInterval(interval)
}
/**
 * @author Juan Andrés Mezzera
 * @method getValue
 * @description Devuelve información acerca de un marcador
 * @param marker - Marcador del que se desea traer información
 */
function getValue(marker){
  var returnValue
  markers.forEach(m =>{
    if (m.title === marker.title){
      returnValue = m
      return
    }
  })
  return returnValue
}

/**
 * @author Juan Andrés Mezzera
 * @method getMarkers
 * @description Trae información del servidor de todos los marcadores y ejecuta el callback
 * @param callback - Callback a ejecutar. Se ejecuta con los marcadores como parámetro
 */
function getMarkers(callback) {
  if (!readyToAsk)
    return
  readyToAsk = false;
  fetch(url,{
    headers:{
      'Authorization': `Bearer ${localStorage.getItem('access-token')}`
    }
  }).then(response => response.json()).then(data =>{
    readyToAsk = true
    var markers = data.map((sucursal, index) => {
      var coords = sucursal.coords
      var lat = coords.split(', ')[0]
      var lng = coords.split(', ')[1]
      var nombreProlijo = sucursal.nombreProlijo
      var nombre = 'M' + index
      var tableros = sucursal.tableros
      return{
        position: {
          lat: lat,
          lng: lng
        },
        name: nombre,
        title: sucursal.nombre,
        status: sucursal.estado,
        sensores: sucursal.sensores,
        nombreProlijo,
        direccion: sucursal.direccion,
        tel: sucursal.tel,
        tableros,
        tipo: sucursal.tipo,
      }
    })
    callback(markers)
  })
  .catch(err => console.error(err))
}

function clearMarkers(){
  markers = []
}

export default initializeMarkers
export {getValue, markers, clearMarkersInterval, clearMarkers}