export default {
  items: [
    {
      title: true,
      name: 'Inicio',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    {
      name: 'Mapa',
      url: '/mapContainer',
      icon: 'icon-map'
    },
      "...", //Se usar para identificar en qué lugar se insertarán las sucursales y los tableros
    {
      title: true,
      name: 'Configuración',
      wrapper: {            // optional wrapper object
        element: '',        // required valid HTML5 element tag
        attributes: {}        // optional valid JS object with JS API naming ex: { className: "my-class", style: { fontFamily: "Verdana" }, id: "my-id"}
      },
      class: ''             // optional class names space delimited list for title item ex: "text-center"
    },
    // {
    //   name: 'Alertas',
    //   url: '/Alertas',
    //   icon: 'icon-bell',
    // },
    /*{
      name: 'Usuarios',
      url: '/Usuarios',
      icon: 'icon-user',
    },
    {
      name: 'Otras configuraciones',
      url: 'http://www.google.com',
      icon: 'icon-settings',
    },*/
  ]
};
