import config from '../config'


function authenticate(mail, pass, callback){
    const url = config.serverUrl + ':' +  config.serverPort + '/api/user/login'
    fetch(url,{
        headers:{
            'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({'username': mail, 'password': pass})
    })
    .then(response => response.json())
    .then(body => callback(null, body))
    .catch(error => callback(error))


}

async function verifyToken(){
    const token = localStorage.getItem('access-token')
    if (token && token === "")
        return false
    const url = config.serverUrl + ':' +  config.serverPort + '/api/user/verifyToken'
    var verified = null
    await fetch(url,{
        headers:{
            'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({'token': token})
    })
    .then(response => verified = response.status === 200)
    return verified
}

function changePass(password, newPassword){
    const url = config.serverUrl + ':' +  config.serverPort + '/api/user'
    return fetch(url,{
        headers:{
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access-token')}`,
        },
        method: "PATCH",
        body: JSON.stringify({password, newPassword})
    })
}

function addUser(username, password, rol, sucursales){
    const url = config.serverUrl + ':' +  config.serverPort + '/api/user'
    return fetch(url,{
        headers:{
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('access-token')}`,
        },
        method: "PUT",
        body: JSON.stringify({username, password, rol, sucursales})
    })
}

function generateLoginUrl(){
    return window.location.origin + "/?#/login"
}
export default authenticate
export {verifyToken, changePass, addUser, generateLoginUrl}