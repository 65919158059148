import React, { Component } from 'react';
import PropTypes from 'prop-types';
import getServiceData from '../../services/serviceData'
import config from '../../config'


const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultFooter extends Component {
  constructor(props){
    super(props)
    this.state = {service: ""}
  }
  componentDidMount(){
    getServiceData((service)=>{
      this.setState({service})
    })
  }
  footerLogo(){
    const service = localStorage.getItem('service')
    if (service && service.length > 0)
      return <img className="logo_service" alt="logo" src={`${config.serverUrl}:${config.serverPort}/public/images/${service}.png`}/>
    else
      return null
  }
  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        {/* <span>{this.state.service}</span> */}
        {this.footerLogo()}
        <span className="desarrollado">Desarrollado por <a href="https://www.arnaldocastro.com.uy/">Arnaldo C. Castro S.A.</a></span>
      </React.Fragment>
    );
  }
}

DefaultFooter.propTypes = propTypes;
DefaultFooter.defaultProps = defaultProps;

export default DefaultFooter;
