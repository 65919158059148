import React, { Component } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane, ListGroup, ListGroupItem } from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getAlerts, handleAlert, alertas, vistas, importantes } from '../../services/alerts'
import {getAllNotifier} from '../../services/notifier.js';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultAside extends Component {

  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      getAllNotifier()
        .then(data => {
          if (this.state.alerts === undefined) {
            this.setState({ alerts: data.filter(alerta => alerta.rule_status === "NOTIFIED") })
            this.props.updateCant(data.filter(alerta => alerta.rule_status === "NOTIFIED").length)
          }
          else if (!this.compareAlerts(this.state.alerts, data)) {
            // console.log(this.compareAlerts(this.state.alerts, data));
            this.setState({ alerts: data.filter(alerta => alerta.rule_status === "NOTIFIED") })
            this.props.updateCant(data.filter(alerta => alerta.rule_status === "NOTIFIED").length)
          }
        }).catch(console.error)
    }, 4000)
  }
  componentWillUnmount() {
    clearInterval(this.interval)
  }
  /**
   * @method compareAlerts
   * @author Juan Andrés Mezzera
   * @description Compara dos arrays de alertas de forma que sólo se renderice la página si cambió la lista de alertas
   * @param a Primera lista de alertas
   * @param b Segunda lista de alertas
   * @returns True sí y sólo sí las listas son iguales
   */
  compareAlerts(a, b) {
    if (a === undefined || b === undefined)
      return false
    if (a.length !== b.length)
      return false
    for (let index in a)
      if (JSON.stringify(a[index]) !== JSON.stringify(b[index]))
        return false
    return true
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  alertsToComponents(alerts, status) {
    if (alerts === undefined)
      return null
    return alerts.map((alert, index) => {
      let alertaJson = JSON.parse(alert.pretty_rule)
      let operador = ""
      switch (alertaJson.operacion) {
        case "between":
          operador = "Dentro del rango: "
          break
        case "notBetween":
          operador = "Fuera del rango: "
          break
        case "greaterThan":
          operador = "es mayor a: "
          break
        case "lessThan":
          operador = "es menor a: "
          break
        default:
          operador = "operador "
      }
      // if (alert.rule_status !== "ACTIVE")
      //   return null
      return (
        <ListGroupItem key={'alert' + index} action tag="a" className="list-group-item-accent-warning list-group-item-divider">

          <div>{alertaJson.atributo + ": " + operador + " " + alertaJson.valor} </div>
          <small className="text-muted mr-3">
            <i className="icon-location-pin"></i>&nbsp; {alertaJson.sensor}
          </small>

        </ListGroupItem>
      )
    })
  }

  handleAlerts(id, newStatus) {
    handleAlert(id, newStatus)
  }

  render() {
    return (
      <React.Fragment>
        <Nav tabs>
          <NavItem>
            <NavLink className={classNames({ active: this.state.activeTab === '1' })}
              onClick={() => {
                this.toggle('1');
              }}>
              <i className="fa fa-exclamation-triangle fa-lg"></i>
            </NavLink>
          </NavItem>
          {/* <NavItem>
            <NavLink className={classNames({ active: this.state.activeTab === '2' })}
                     onClick={() => {
                       this.toggle('2');
                     }}>
              <i className="fa fa-check-circle fa-lg"></i>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink className={classNames({ active: this.state.activeTab === '3' })}
                     onClick={() => {
                       this.toggle('3');
                     }}>
              <i className="fa fa-archive"></i>
            </NavLink>
          </NavItem> */}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <ListGroup className="list-group-accent" tag={'div'}>
              {
                this.alertsToComponents(this.state.alerts, 0)
              }

            </ListGroup>
          </TabPane>
          <TabPane tabId="2" className="p-3">
            {
              this.alertsToComponents(this.state.alerts, 1)
            }
          </TabPane>
          <TabPane tabId="3" className="p-3">
            {
              this.alertsToComponents(this.state.alerts, 2)
            }
          </TabPane>
        </TabContent>
      </React.Fragment>
    );
  }
}

DefaultAside.propTypes = propTypes;
DefaultAside.defaultProps = defaultProps;

export default DefaultAside;
