import React from 'react';
import Loadable from 'react-loadable'


function Loading() {
  return <div>Loading...</div>;
}

const VistaSucursales = Loadable({
  loader:() => import('./views/VistaSucursales'),
  loading: Loading,
});

const MapContainer = Loadable({
  loader:() => import('./views/MapContainer'),
  loading: Loading,
});

const VistaAlertas = Loadable({
  loader:() => import('./views/VistaAlertas'),
  loading: Loading,
});


const VistaUsuarios = Loadable({
  loader: () => import('./views/VistaUsuarios'),
  loading: Loading,
});

const AddSensorView = Loadable({
  loader: () => import('./views/AddSensorView'),
  loading: Loading,
});

const AddSucursalView = Loadable({
  loader: () => import('./views/AddSucursalView'),
  loading: Loading,
});

const Unauthorized = Loadable({
  loader: () => import('./views/Pages/Page401'),
  loading: Loading,
})

const Potencia = Loadable({
  loader: () => import('./views/Tablero/Potencia/Potencia'),
  loading: Loading,
})
const CorrientesyVoltajes = Loadable({
  loader: () => import('./views/Tablero/CorrientesyVoltajes/CorrientesyVoltajes'),
  loading: Loading,
})
const ConsumoDiario = Loadable({
  loader: () => import('./views/Tablero/ConsumoDiario/ConsumoDiario'),
  loading: Loading,
})

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config


const privateRoutes = [
  { path: '/tablero/potencia/:sucursal/:sensor', name: 'Potencia', component: Potencia },
  { path: '/tablero/corrientesyvoltajes/:sucursal/:sensor', name: 'Corrientes y Voltajes', component: CorrientesyVoltajes },
  { path: '/tablero/consumodiario/:sucursal/:sensor', name: 'Consumo Diario', component: ConsumoDiario },
  { path: '/mapContainer', name: 'Mapa', component: MapContainer },
  //{ path: '/sucursales', name: 'Sucursales', component: VistaSucursales },
  { path: '/sucursales/:sucursal', strict: true, name: 'Sucursales', component: VistaSucursales },
  { path: '/Alertas', name: 'Alertas', component: VistaAlertas },
  { path: '/Usuarios', name: 'Usuarios', component: VistaUsuarios },
  { path: '/addSensor', exact: true, name: 'Agregar Sensor', component: AddSensorView },
  { path: '/addSucursal', exact: true, name: 'Agregar Sucursal', component: AddSucursalView },
  { path: '/unauthorized', exact: true, name: 'No autorizado', component: Unauthorized },
]
const routes = []
export default routes
export {privateRoutes};
