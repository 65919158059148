import config from '../config.js'

const url = `${config.serverUrl}:${config.serverPort}/api/sucursales/alertas`
var alertas = 0
var vistas = 0 
var importantes = 0
function getAlerts(callback){
    return fetch(url,{
        headers:{
          'Authorization': `Bearer ${localStorage.getItem('access-token')}`,
        }})
        .then(response => response.json())
        .then(data => {
          alertas = data.length
          vistas = data.filter(alerta => alerta.estado === 1).length
          importantes = data.filter(alerta => alerta.estado === 0).length
          return Promise.resolve(data)
        })
}

function handleAlert(id, newStatus){
  return fetch(`${url}/${id}`,{
    headers:{
      'Authorization': `Bearer ${localStorage.getItem('access-token')}`,
      'Content-Type': 'application/json',
    },
    method: 'PATCH',
    body: JSON.stringify({newStatus}),
    })
}
export {getAlerts, handleAlert, alertas, vistas, importantes }