import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';
import SearchField from 'react-search-field';
import * as ReactSwitch from 'react-switch'

import { verifyToken, changePass, addUser, generateLoginUrl } from '../../services/authenticate'
import { markers, clearMarkers } from '../../services/markers'

import './styles.css'


import {
  AppAside,
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../_nav';
// routes config
import routes, { privateRoutes } from '../../routes';
import DefaultAside from './DefaultAside';
import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';

var loaded = false

const loading = function (key) {
  return (
    <div key={key} style={{
      position: "fixed",
      width: "100%",
      height: "100%",
      top: "0",
      left: "0",
      zIndex: "10000",
      backgroundColor: "white",
    }}>Autenticando...
    </div>
  )
}

const SENSOR_TYPES_THAT_HAVE_DASHBOARDS = ["PowerMeterTcN", "PowerMeter"]
class DefaultLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      verified: undefined,
      sucursales: [],
      changePasswordDialog: false,
      addUserDialog: false,
      filteredNav: navigation,
      cantAlerts:0,
    }
    authenticate(this)
  }
  showChangePasswordDialog(_this) {
    if (!this.state.changePasswordDialog)
      this.setState({ changePasswordDialog: true })
  }
  addUser() {
    if (!this.state.addUserDialog)
      this.setState({ addUserDialog: true })
  }
  /**
   * @method hasDashBoardEntry
   * @description Este método se fija si el @param sucursal tiene algún sensor que se muestre en un tablero. 
   * @param sucursal - Sucursal a verificar
   * @returns En caso de que la sucursal tenga algún sensor que se muestre con tablero, devuelve un array con todos los sensores que se muestren en tableros. En caso contrario, devuelve null
   * @author Juan Andrés Mezzera
   */
  hasDashBoardEntry(sucursal) {
    if (sucursal.tableros === 0)
      return null
    else
      return sucursal.sensores.filter(sensor => SENSOR_TYPES_THAT_HAVE_DASHBOARDS.indexOf(sensor.tipo) !== -1) //Se fija si el array SENSOR_TYPES_THAT_HAVE_DASHBOARDS contiene al tipo de cada uno de los sensores
  }
  hasNotDashBoardEntry(sucursal) {
    if (sucursal.tableros === 0)
      return true
    return sucursal.sensores.filter(sensor => SENSOR_TYPES_THAT_HAVE_DASHBOARDS.indexOf(sensor.tipo) === -1).length > 0
  }
  componentDidMount() {
    this.interval = setInterval(() => {
      if (markers.length !== 0) {
        //eslint-disable-next-line
        this.state.sucursales = markers
        clearInterval(this.interval)
        var sucursales = []
        let notDashBoardEntries = []
        let dashBoardEntries = []
        for (var i in markers) {
          if (!i)
            continue
          var sucursal = markers[i]
          var sensors
          //eslint-disable-next-line
          if (sensors = this.hasDashBoardEntry(sucursal)) {
            //eslint-disable-next-line
            const children = sensors.map(sensor => {
              return {
                name: sensor.nombreProlijo,
                icon: 'icon-home',
                children: [
                  {
                    name: 'Potencia',
                    url: '/tablero/potencia/' + sucursal.title + '/' + sensor.nombreEnCygnus,
                    icon: 'icon-puzzle',
                  },
                  {
                    name: 'Corrientes y Voltajes',
                    url: '/tablero/corrientesyvoltajes/' + sucursal.title + '/' + sensor.nombreEnCygnus,
                    icon: 'icon-puzzle',
                  },
                  {
                    name: 'Consumo',
                    url: '/tablero/consumodiario/' + sucursal.title + '/' + sensor.nombreEnCygnus,
                    icon: 'icon-puzzle',
                  },
                ]
              } 
            })
            dashBoardEntries.push({
              name: sucursal.nombreProlijo,
              url: '/tablero/' + sucursal.title,
              icon: 'icon-home',
              children
              
            })
          }
          if (this.hasNotDashBoardEntry(sucursal)) {
            notDashBoardEntries.push({
              name: sucursal.nombreProlijo,
              url: '/sucursales/' + sucursal.title,
              icon: 'icon-home',
            })
          }
        }
        const sucursalesHeader = {
          title: true,
          name: 'Sucursales',
          url: '/sucursales',
        }
        const dashBoardsHeader = {
          title: true,
          name: 'Tableros',
          url: '/tablero',
        }
        if (notDashBoardEntries.length > 0)
          sucursales.push(...[sucursalesHeader, ...notDashBoardEntries])
        if (dashBoardEntries.length > 0)
          sucursales.push(...[dashBoardsHeader, ...dashBoardEntries])

        for (var index = 0; index < navigation.items.length; index++)
          if (navigation.items[index] === "...")
            navigation.items.splice.apply(navigation.items, [index, 1].concat(sucursales)) //Elimina el "..." y concatena todo array de sucursales en la posición index
        
        this.forceUpdate()
        loaded = true
      }
    }, 100)
    if (localStorage.getItem("service") === "admin") {
      const addSensor = {
        name: "Agregar sensor",
        url: "/addSensor",
        icon: 'icon-plus'
      }
      const addSucursal = {
        name: "Agregar sucursal",
        url: "/addSucursal",
        icon: 'icon-plus'
      }
      navigation.items.push(addSensor)
      navigation.items.push(addSucursal)
    }

  }
  componentWillUnmount() {
    var i = 0
    while (navigation.items[i].name !== "Sucursales" && navigation.items[i].name !== "Tableros")
      i++

    if (markers.length !== 0)
      navigation.items.splice(++i, markers.length - 1)
    clearMarkers()
  }
  updateAlertCounts(alertas, vistas, importantes, _this) {
    _this.setState({ alertas, vistas, importantes })
  }
  hideDialog(dialog) {
    if (dialog === "password") {
      this.setState({ changePasswordDialog: false })
    }
    if (dialog === "addUser") {
      this.setState({ addUserDialog: false })
    }
  }
  filter(value, _this) {
    if (value === "") {
      _this.setState({ filteredNav: navigation })
      return
    }
    var toReturn = {}
    var filtered = []
    var i = 0
    while (navigation.items[i].name !== "Sucursales")
      i++
    i++
    for (let j = i; j <= i + _this.state.sucursales.length; j++)
      if (navigation.items[j].name.toLowerCase().includes(value.toLowerCase()))
        filtered.push(navigation.items[j])
    toReturn.items = filtered
    _this.setState({ filteredNav: toReturn })

  }

  CantidadDeAlarmas(numero){
    this.setState({cantAlerts:numero})
  }

  render() {
    if (localStorage.getItem("access-token") === undefined || localStorage.getItem("access-token") === "") {
      window.location.replace(generateLoginUrl())
      return null
    }
    return (
      <div className="app">
        <PasswordDialog display={this.state.changePasswordDialog} parent={this} />
        <AddUserDialog display={this.state.addUserDialog} parent={this} />
        <AppHeader fixed >
          <DefaultHeader cantidad={this.state.cantAlerts} alerts={this.state.alertas} vistas={this.state.vistas}
            importantes={this.state.importantes} showPasswordDialog={() => this.showChangePasswordDialog(this)}
            addUser={() => this.addUser(this)}
          />
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg">
            <AppSidebarHeader />
            <AppSidebarForm />
            <SearchField
              classNames="searchmodificado"
              placeholder='Buscar'
              onChange={(value) => this.filter(value, this)}
            />
            <AppSidebarNav navConfig={this.state.filteredNav} {...this.props} />
            <AppSidebarFooter />
            <AppSidebarMinimizer />
          </AppSidebar>
          <main className="main">
            <Container fluid>
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (<Route key={idx} path={route.path} exact={route.exact} name={route.name} render={props => (
                    <route.component {...props} />
                  )} />)
                    : (null);
                },
                )}
                {/**
                  Las rutas definidas como privadas sólo podrán ser vistas por un usuario que haya ingresado sesión.
                  El siguiente algoritmo verifica que exista un token válido (consulta al servidor para saber si es válido).
                  Debido al tiempo de respuesta del servidor, existe un intervalo en el que el cliente no sabe si el token es valido, en ese intervalo
                  muestra un cartel diciendo que está autenticando. Luego, si es válido el acceso a la ruta, deja acceder; si no, redirige al login
                 */}
                {
                  privateRoutes.map((route, idx) => {
                    if (this.state.verified === undefined)
                      return (loading(idx))
                    if (!this.state.verified) {
                      window.location.replace(generateLoginUrl())
                      return null
                    }
                    return (route.component ? (<Route key={idx} path={route.path} exact={route.exact} strict={true} name={route.name} render={props => (
                      <route.component {...props} />
                    )} />)
                      : (null))
                  })
                }
                {redirect(this)}
                {/* <Redirect from="/" to="/mapContainer" /> */}
              </Switch>
            </Container>
          </main>
          <AppAside fixed>
            <DefaultAside updateCant={(e)=> this.CantidadDeAlarmas(e)} updateAlertCounts={this.updateAlertCounts} parent={this} />
          </AppAside>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}
class PasswordDialog extends Component {
  hidePasswordDialog() {
    this.props.parent.hideDialog("password")
  }
  render() {
    if (!this.props.display)
      return null
    return (
      <div className="formWrapper" style={{ top: "25%", position: "absolute", zIndex: 10, width: "100%", textAlign: "center" }} >
        <form style={{ width: "30%", margin: "auto" }}>
          <div className="formHeader" style={{ position: "relative", display: "block" }} onClick={() => this.hidePasswordDialog()}>
            <i className="fa fa-close fa-lg"></i>
          </div>
          <div className="oldPassword" style={{ position: "relative", display: "block" }}>
            <span>Ingrese su contraseña anterior</span>
            <input type="password" placeholder="Contraseña anterior" name="password" className="oldPasswordInput" />
          </div>
          <div className="newPassword" style={{ position: "relative", display: "block" }}>
            <span>Ingrese su nueva contraseña</span>
            <input type="password" placeholder="Nueva contraseña" name="newPassword" className="newPasswordInput" />
          </div>
          <div className="newPassword" style={{ position: "relative", display: "block" }}>
            <span>Repita su nueva contraseña</span>
            <input type="password" placeholder="Nueva contraseña" className="newPasswordInput2" />
          </div>
          <div style={{ position: "relative", marginTop: "10px" }}>
            <input className="px-4 btn btn-danger btnenviar" type="button" onClick={() => this.send()} value="Aceptar" />
          </div>
        </form>
      </div>
    )
  }
  send() {
    const oldPass = document.querySelector(".oldPasswordInput").value
    const newPass = document.querySelector(".newPasswordInput").value
    const newPass2 = document.querySelector(".newPasswordInput2").value

    if (newPass !== newPass2) {
      alert("Las contraseñas no coinciden")
      return
    }

    changePass(oldPass, newPass)
      .then(res => {
        switch (res.status) {
          case 204:
            alert("Contraseña modificada")
            this.props.parent.hideDialog("password")
            break
          case 403:
            alert("Contraseña incorrecta")
            break
          case 500:
            alert("Error desconocido. Por favor vuelva a intetarlo en breves.")
            break
          default:
            throw new Error("Unexpected server error")
        }
      })
      .catch(err => console.error(err))

  }
}

class AddUserDialog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checked: false,
      selectedSucursales: [],
    }
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(checked) {
    this.setState({ checked });
  }

  hideUserDialog() {
    this.props.parent.hideDialog("addUser")
    this.setState({
      selectedSucursales: []
    })
  }
  send() {
    const username = document.querySelector(".usernameInput").value
    const password = document.querySelector(".passwordInput").value
    const password2 = document.querySelector(".passwordInput2").value

    if (password !== password2) {
      alert("Las contraseñas no coinciden")
      return
    }

    addUser(username, password, this.state.checked, this.state.selectedSucursales.join(','))
      .then(res => {
        if (res.status === 201) {
          alert("Usuario creado")
          this.props.parent.hideDialog("addUser")
          this.setState({ selectedSucursales: [] })
        } else if (res.status === 403) {
          alert("Usted no tiene autorizacón para agregar usuarios")
          this.props.parent.hideDialog("addUser")
          this.setState({ selectedSucursales: [] })
        }
        else
          alert("Error desconocido. Por favor vuelva a intetarlo en breves.")
      }
      )
      .catch(console.err)


  }
  handleChkBoxChange(sucursal) {
    let sucursales = this.state.selectedSucursales

    let index = sucursales.indexOf(sucursal.nombreProlijo)
    if (index >= 0)
      sucursales.splice(index, 1)
    else
      sucursales.push(sucursal.nombreProlijo)

    this.setState({ selectedSucursales: sucursales })

  }
  render() {
    if (!this.props.display)
      return null

    return (
      <div className="addUserDialogWrapper" style={{ top: "25%", position: "absolute", zIndex: 10, width: "100%", textAlign: "center" }}>
        <div className="addUserDialog" style={{ width: "30%", margin: "auto" }}>
          <div className="formHeader" style={{ position: "relative", display: "block" }} onClick={() => this.hideUserDialog()}>
            <i className="fa fa-close fa-lg"></i>
          </div>
          <div className="username">
            <span>Ingrese el nombre de usuario</span>
            <input type="text" className="usernameInput" placeHolder="Usuario" />
          </div>
          <div className="password">
            <span>Ingrese la contraseña</span>
            <input type="password" className="passwordInput" placeHolder="Contraseña" />
          </div>
          <div className="password">
            <span>Repita la contraseña</span>
            <input type="password" className="passwordInput2" placeHolder="Repetir Contraseña" />
          </div>
          <div className="userOptions">
            <div className="adminSwitch">
              <label htmlFor="switch" className>
                <span>Viewer</span>
                <ReactSwitch
                  className="switch"
                  onChange={this.handleChange}
                  checked={this.state.checked}
                  id="switch"
                  onColor="#86d3ff"
                  onHandleColor="#2693e6"
                  handleDiameter={30}
                  uncheckedIcon={false}
                  checkedIcon={false}
                />
                <span>Admin</span>
              </label>
            </div>
            <div className="selectSucursales">
              {this.props.parent.state.sucursales.map((sucursal, index) => {
                return (
                  <div className="sucursalOption" key={"sucursalOption" + index}>
                    <input type="checkbox" className="sucursalChkBox" key={"sucursalChkBox" + index} disabled={this.state.checked} onChange={(e) => this.handleChkBoxChange(sucursal)} />
                    <span className="sucursalSpan" key={"sucursalSpan" + index}>{sucursal.nombreProlijo}</span>
                  </div>
                )
              })}
            </div>
          </div>
          <input className="px-4 btn btn-danger btnenviar" type="button" onClick={() => this.send()} value="Enviar" />
        </div>
      </div>
    )
  }
}

function redirect(_this) {
  if (_this.state.verified) {
    return <Redirect from="/" to="/mapContainer" />
  }
}
async function authenticate(theThis) {
  var verified = await verifyToken()
  theThis.setState({ verified: verified })
}

export default DefaultLayout;
export {loaded}
